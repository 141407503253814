.prevent-click-background {
    position:fixed;
    background: rgba(0,0,0,0.5);
    width:100%;
    height:100%;
}

.loading-spinner {
    position: relative;
    left: calc(50% - 32px);
    top: calc(50% - 32px);
    width: 64px;
    height: 64px;
}

.loading-spinner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: #007bff;
    animation: spin 1s ease-in-out infinite;
}

.chat-container  {
    width: calc(100% - 24px);
    height: 500px;
    margin: 12px 12px 0px;
    padding: 0px;
    border: 1px solid #787878;
}

.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .chat__messages {
    flex: 1;
    padding: 10px;
    overflow-y: scroll;
  }
  
.chat__message_box {
    margin-bottom: 10px;
}

  .chat__message {
    padding: 8px 12px;
    /* max-width: 80%; */
    width: auto;
    display:inline-block;
    border-radius: 12px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    align-self: flex-start;
  }
  
  .chat__message:last-child {
    margin-bottom: 0;
  }
  
  .chat__message--mine {
    background-color: #e1ffc7;
    align-self: flex-end;
  }
  
  .chat__input {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chat__input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    outline: none;
  }
  
  button,
  .chat__input button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0080ff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  button:hover,
  .chat__input button:hover {
    background-color: #0059b3;
  }
  
  .bot {
    padding-left: 4px;
    margin-bottom: 4px;
    color: #0080ff;
    font-weight:700;
  }

  .me {
    padding-left: 4px;
    margin-bottom: 4px;
    color: black;
    font-weight:700;
  }

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}